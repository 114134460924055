
// ConfirmPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FlashMessage from '../components/FlashMessage';

const ConfirmPage = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    console.log('CFP useEffect');
    
    const confirmEmail = async (token) => {
      console.log('CFP useEffect ConfirmEmail');
      try {
        console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
        setMessage(t('confirmPage.messages.validatingLink'));
        setMessageType('warning');

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/confirm/${token}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.log('CFP response NOT OK');
          throw new Error(t(errorData.error) || t('general.somethingWentWrong'));
        }

        const data = await response.json();
        console.log('CFP data:', data);
        console.log('CFP data.message:', data.message);
        console.log('CFP data.messageType:', data.messageType);

        setMessage(t(data.message));
        setMessageType(data.messageType);
      } catch (error) {
        console.error('CFP Fetch error:', error);
        setMessage(t(error.message));
        setMessageType('error');
      }
    };

    confirmEmail(token);
  }, [token, t]);

  return (
    <div>
      <h3>{t('confirmPage.confirmRegistrationEmail')}</h3>
      {message && <FlashMessage message={message} type={messageType} />}
    </div>
  );
};

export default ConfirmPage;

