// ContactUsPage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import topBannerImage from '../assets/images/contactUs1Detail.webp'; // Import your top image
import QuestionCommentForm from '../components/QuestionCommentForm'; 
import '../css/ContactUsPage.css'; // Import the CSS file for custom styles
console.log('Start of ContactUsPage.js');

const ContactUsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="contactUs-page">
      {/* Full-width top image */}
      <img src={topBannerImage} alt="Top Banner" className="top-banner" />
      <legend className="border-bottom mb-4">{t('contactUsPage.legend')}</legend>
      <QuestionCommentForm /> {/* Include the QuestionCommentForm component */}
    </div>
  );
};
export default ContactUsPage;