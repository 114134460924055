// AnnouncementsPage.js;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import topBannerImage from '../assets/images/announcementsHeaderDetail.webp'; // Import your top image
import '../css/AnnouncementsPage.css'; // Import the CSS file for custom styles
console.log('Start of AnnouncementsPage.js');

const AnnouncementsPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch('/api/announcements');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Announcements:', data);
        setAnnouncements(data);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setAnnouncements([]);
      }
    };

    fetchAnnouncements();
  }, []);

  const toggleShowMore = (index) => {
    setExpandedIndexes((prevExpandedIndexes) =>
      prevExpandedIndexes.includes(index)
        ? prevExpandedIndexes.filter((i) => i !== index)
        : [...prevExpandedIndexes, index]
    );
  };

  return (
    <div className="announcements-page">
      {/* Full-width top image */}
      <img src={topBannerImage} alt="Top Banner" className="top-banner" />

      <legend className="border-bottom mb-4">{t('announcementsPage.legend')}</legend>

      <div className="row">
        {Array.isArray(announcements) && announcements.length > 0 ? (
          announcements.map((announcement, index) => {
            const isExpanded = expandedIndexes.includes(index);
            const textPreview = isExpanded
              ? announcement.text
              : announcement.text.split(' ').slice(0, 100).join(' ') + '...';

            return (
              <div className="col-md-6 mb-4" key={index}>
                <div className="announcement-box">
                  <h4>{announcement.date}</h4>
                  <img 
                    src={require(`../assets/images/${announcement.imageName}`)} 
                    alt={announcement.title} 
                    className="announcement-image" 
                  />
                  <h2>{announcement.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: textPreview }} />
                  <button
                    className="btn btn-link show-more-btn"
                    onClick={() => toggleShowMore(index)}
                  >
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p>No announcements available.</p>
        )}
      </div>
    </div>
  );
};

export default AnnouncementsPage;
