// HomePage.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import c3v60Logo from '../assets/images/c3v60_logo.jpg'; // Adjusted path
import flexiblePlatform from '../assets/images/flexiblePlatformSide.webp'; // Adjusted path
import boxesWithModules from '../assets/images/boxesWithModules.webp'; // Adjusted path
import securityLock from '../assets/images/securityLock.webp'; // Adjusted path
import integrationWithOtherSystems from '../assets/images/integrationOtherSystems.webp'; // Adjusted path
import '../css/HomePage.css'; // Import the CSS file

const HomePage = () => {
  const { t } = useTranslation();

  const content = [
    {
      id: 1,
      image: flexiblePlatform, // Use appropriate images
      title: t('homePage.content2Title'),
      text: t('homePage.content2'), // Use appropriate translation keys
      detailsLink: '/details/1', // Update with correct paths
    },
    {
      id: 2,
      image: boxesWithModules,
      title: t('homePage.content3Title'),
      text: t('homePage.content3'),
      detailsLink: '/details/2',
    },
    {
      id: 3,
      image: securityLock,
      title: t('homePage.content4Title'),
      text: t('homePage.content4'), // Use appropriate translation keys
      detailsLink: '/details/2',
    },
    {
      id: 4,
      image: integrationWithOtherSystems,
      title: t('homePage.content5Title'),
      text: t('homePage.content5'), // Use appropriate translation keys
      detailsLink: '/details/2',
    },
    // Add more objects as needed
  ];

  return (
    <div>
      {/* Top Text Section */}
      <div className="text-center">
        <h1>{t('homePage.legend')}</h1>
        <h3>{t('homePage.empower')}<img src={c3v60Logo} height="50" alt="c3v60" /></h3>
        <h4>{t('homePage.ultimate')}</h4>
        <h5>{t('homePage.content1')}</h5>
      </div>

      {/* Grid Section */}
      <div className="row justify-content-center">
        {content.map((item) => (
          <div key={item.id} className="col-md-4 mb-4">
            <ExpandableBox item={item} />
          </div>
        ))}
      </div>
      {/* Bottom Text Section */}
      <div className="text-center">
        <h3>{t('homePage.joinRevolution')}</h3>
        {/* Button placed outside of collapsible section to ensure visibility */}
        <Link to="/register" className="btn btn-primary ms-3 mb-4" style={{ backgroundColor: '#1520bf', borderColor: '#1520bf' }}>
          {t('layout.buttons.requestDemo')}
        </Link>
      </div>
    </div>
  );
};

// const ExpandableBox = ({ item }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const handleToggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const displayedText = isExpanded ? item.text : `${item.text.slice(0, 200)}...`;

//   return (
//     <div className="card h-100 expandable-box">
//       <img src={item.image} className="card-img-top fixed-image" alt="..." />
//       <div className="card-body">
//         <p className="card-title">{item.title}</p>
//         <p className="card-text">{displayedText}</p>
//         <button onClick={handleToggleExpand} className="btn btn-link">
//           {isExpanded ? 'Show Less' : 'Show More'}
//         </button>
//         <Link to={item.detailsLink} className="stretched-link"></Link>
//       </div>
//     </div>
//   )
// };


const ExpandableBox = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = (e) => {
    e.stopPropagation(); // Prevent the click from triggering the navigation
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded ? item.text : `${item.text.slice(0, 200)}...`;

  return (
    <div className="card h-100 expandable-box">
      <img src={item.image} className="card-img-top fixed-image" alt="..." />
      <div className="card-body">
        <p className="card-title">{item.title}</p>
        <p className="card-text">{displayedText}</p>
        <button onClick={handleToggleExpand} className="btn btn-link">
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
        {/* Remove the stretched-link class */}
        <Link to={item.detailsLink} className="btn btn-primary mt-2">
          Go to Details
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
