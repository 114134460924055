import React from 'react';
import { useTranslation } from 'react-i18next';
import topBannerImage from '../assets/images/termsOfServiceDetail.webp'; // Example top banner image
import '../css/PrivacyPolicyPage.css'; // Import the CSS file for custom styles
console.log('Start of TermsOfServicePage.js');

const TermsOfServicePage = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-page">
        {/* Full-width top image */}
        <img src={topBannerImage} alt="Top Banner" className="top-banner" />
        <legend className="border-bottom mb-4">{t('termsOfServicePage.legend')}</legend>
        <div className="privacy-content">
            <h3>{t('termsOfServicePage.effectiveDate')}</h3>
            <p className="indent-paragraph">{t('termsOfServicePage.topText')}</p>

            <h4>{t('termsOfServicePage.section1Title')}</h4>
            <p className="indent-paragraph">{t('termsOfServicePage.section1Text')}</p>

            <h4>{t('termsOfServicePage.section2Title')}</h4>
            <p className="indent-paragraph">{t('termsOfServicePage.section2Text')}</p>

            <h4>{t('termsOfServicePage.section3Title')}</h4>
            <ul className="no-space-list indent-list">
                <li>{t('termsOfServicePage.section3Text1')}</li>
                <li>{t('termsOfServicePage.section3Text2')}</li>
                <li>{t('termsOfServicePage.section3Text3')}</li>
            </ul>

            <h4>{t('termsOfServicePage.section4Title')}</h4>
            <ul className="no-space-list indent-list">
                <li>{t('termsOfServicePage.section4Text1')}</li>
                <li>{t('termsOfServicePage.section4Text2')}</li>
                <li>{t('termsOfServicePage.section4Text3')}</li>
                <li>{t('termsOfServicePage.section4Text4')}</li>
                <li>{t('termsOfServicePage.section4Text5')}</li>
            </ul>

            <h4>{t('termsOfServicePage.section5Title')}</h4>
            <ul className="no-space-list indent-list">
                <li>{t('termsOfServicePage.section5Text1')}</li>
                <li>{t('termsOfServicePage.section5Text2')}</li>
            </ul>

            <h4>{t('termsOfServicePage.section6Title')}</h4>
            <p className="indent-paragraph">{t('termsOfServicePage.section6Text')}</p>

            <h4>{t('termsOfServicePage.section7Title')}</h4>
            <ul className="no-space-list indent-list">
                <li>{t('termsOfServicePage.section7Text1')}</li>
                <li>{t('termsOfServicePage.section7Text2')}</li>
                <li>{t('termsOfServicePage.section7Text3')}</li>
            </ul>

            <h4>{t('termsOfServicePage.section8Title')}</h4>
            <p className="indent-paragraph">{t('termsOfServicePage.section8Text')}</p>

            <h4>{t('termsOfServicePage.section9Title')}</h4>
            <p className="indent-paragraph">{t('termsOfServicePage.section9Text')}</p>

            <h4>{t('termsOfServicePage.section10Title')}</h4>
            <ul className="no-space-list indent-list">
                <li>{t('termsOfServicePage.section10Text1')}</li>
                <li>{t('termsOfServicePage.section10Text2')}</li>
            </ul>

            <h4>{t('termsOfServicePage.section11Title')}</h4>
            <p className="indent-paragraph">{t('termsOfServicePage.section11Text')}</p>

            <h4>{t('termsOfServicePage.section12Title')}</h4>
            <p className="indent-paragraph mb-2">{t('termsOfServicePage.section8Text')}</p>
        </div>
    </div>
  );
};

export default TermsOfServicePage;
