import { useState, useEffect, useCallback } from 'react';

const useForm = (initialValues, validate, clearFlashMessage) => {
    console.log('useForm hook initialized')
    const [formData, setFormData] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isDirty, setIsDirty] = useState({});

    const validateForm = useCallback(() => {
        console.log('useForm validteForm')
        const errors = validate(formData);
        setFormErrors(errors);
    }, [formData, validate]);

    useEffect(() => {
        validateForm();
    }, [validateForm]);

    const validateField = useCallback((name, value) => {
        console.log('useForm validateField')
        const errors = validate({ ...formData, [name]: value });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errors[name] || '',
        }));
    }, [formData, validate]);

    const handleChange = useCallback((e) => {
        console.log('useForm handleChange')
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setIsDirty((prevState) => ({
            ...prevState,
            [name]: true,
        }));
        validateField(name, value); // Validate the individual field
        clearFlashMessage();
    }, [clearFlashMessage, validateField]);

    const handleFocus = useCallback((e) => {
        console.log('useForm handleFocus')
        const { name } = e.target;
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Clear the error for this field
        }));
    }, []);

    const handleSubmit = useCallback((e, callback) => {
        if (e && e.preventDefault) {
            e.preventDefault(); // Prevent default form submission
        }
        console.log('useForm handleSubmit');
        const errors = validate(formData);
        setFormErrors(errors);
        setIsDirty(Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
        if (Object.keys(errors).length === 0) {
            if (typeof callback === 'function') {
                callback();
            } else {
                console.error('Callback is not a function:', callback);
            }
        }
    }, [formData, validate]);
    
    const resetForm = useCallback(() => {
        console.log('useForm resetForm')
        setFormData(initialValues);
        setFormErrors({});
        setIsDirty({});
    }, [initialValues]);

    return [formData, formErrors, handleChange, handleSubmit, resetForm, isDirty, handleFocus];
};

export default useForm;
