// AboutPage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
console.log('Start of AboutPage.js');

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <legend className="border-bottom mb-4">{t('aboutPage.companyOverviewText')}</legend>
      <p>{t('aboutPage.foundedText1')}</p>
      <p>{t('aboutPage.foundedText2')}</p>
    </div>
  );
};
export default AboutPage;