// import i18n from "i18next";
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     // Remove repeated 'ns' keys and define them in a single array if needed. 
//     // However, given all translations are in a single file, specifying multiple namespaces here is not necessary unless you have a specific reason to keep them.
//     // ns: ['translation', 'homePage', 'aboutPage', 'registerPage', 'registerForm'],
//     defaultNS: 'translation',
//     fallbackLng: ['en'],
//     supportedLngs: ['en', 'fr'],
//     react: {
//       useSuspense: true
//     },    
//     debug: true,
//     detection: {
//       order: ["queryString", "cookie"],
//       cache: ["cookie"],
//     },
//     interpolation: {
//       escapeValue: false,
//     },
//     backend: {
//       // Adjusted loadPath to always point to translation.json, ignoring the namespace in the file path.
//       loadPath: '/static/locales/{{lng}}/translation.json',
//     },
//   });

// export default i18n;

import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend) // Loads translation files from a backend server
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if the detected language is not available
    supportedLngs: ['en', 'fr'], // List of supported languages
    defaultNS: 'translation', // Default namespace for translation files
    debug: true, // Enable debug mode for development

    // Options specific to language detection
    detection: {
      order: ["queryString", "cookie"], // Order of language detection
      cache: ["cookie"], // Cache the detected language in cookies
    },

    // Options specific to handling missing keys and translation
    saveMissing: true, // Enable saving missing keys
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.warn(`Missing translation for key: ${key}`); // Log the missing key
      return fallbackValue || `Translation not available`; // Return a default message
    },

    // Interpolation settings
    interpolation: {
      escapeValue: false, // React already handles XSS protection
    },

    // Backend settings for loading translation files
    backend: {
      loadPath: '/static/locales/{{lng}}/translation.json', // Path to translation files
      // If you have a server-side handler for missing keys, you can set
      // addPath to send these keys to the server:
      // addPath: '/static/locales/add/{{lng}}/{{ns}}',
    },

    // React-specific options
    react: {
      useSuspense: true // Use suspense for loading translations
    },
  });

export default i18n;
