// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ErrorBoundary from './components/ErrorBoundary';
import { FlashMessageProvider } from './components/FlashMessageContext';
import FlashMessage from './components/FlashMessage';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactUsPage from './pages/ContactUsPage';
import ModulesPage from './pages/ModulesPage';
import AnnouncementsPage from './pages/AnnouncementsPage';
import RegisterPage from './pages/RegisterPage';
import ResendConfirmation from './pages/ResendConfirmation';
import ConfirmPage from './pages/ConfirmPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import AdminDashboard from './pages/AdminDashboard';
import AdminLogin from './pages/AdminLogin';
import Layout from './components/Layout';
import DynamicScriptLoader from './components/DynamicScriptLoader';
console.log('Start of App.js');

const RouteChangeTracker = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  return null;
};

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-VPM157JX51');
  }, []);
  console.log('App Rendered')
  return (
    <Router>
      <DynamicScriptLoader />
      <RouteChangeTracker />
      <FlashMessageProvider>
        <Layout>
          <FlashMessage />
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/modules" element={<ModulesPage />} />
              <Route path="/announcements" element={<AnnouncementsPage />} />
              <Route path="/confirm/:token" element={<ConfirmPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/terms-of-service" element={<TermsOfServicePage />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/resend_confirmation" element={<ResendConfirmation />} />
            </Routes>
          </ErrorBoundary>
        </Layout>
      </FlashMessageProvider>
    </Router>
  );
};

export default App;
