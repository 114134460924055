import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FlashMessage from './FlashMessage';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import c3visionLogo from '../assets/images/c3vision_logo_no_bg.png';
import { useTranslation } from 'react-i18next';
// import ylHsPortion from '../assets/images/yl_hs_portion.jpg';
// import cwMain from '../assets/images/cw_main.jpg';
// import whRobots from '../assets/images/wh_robots.jpg';
// import msInvoice from '../assets/images/ms_invoice.jpg';
// import crmCallCenter from '../assets/images/crm_call_center.jpg';
// import tmThenNowPortion1 from '../assets/images/tm_then_now_portion1.jpg';
// import smbImage1 from '../assets/images/smb_image1.jpg';

console.log('Start of Layout.js');

const Layout = ({ children }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  // Collapse the navbar when the location changes
  React.useEffect(() => {
    setIsNavCollapsed(true);
  }, [location]);

  return (
    <>
      <ScrollToTop /> {/* Add ScrollToTop here */}
      <Helmet>
        <meta name="description" content="" />
        <meta name="author" content="The c3vision Dev Team" />
        <meta name="generator" content="c3vision" />
        {/* Add any other tags that you need here, e.g., meta tags */}
      </Helmet>
      <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#E0E0E0' }}>
        <div className="container">
          {/* Hamburger button moved before the logo */}
          <button className="navbar-toggler" type="button" onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link to="/" className="navbar-brand">
            <img src={c3visionLogo} height="50" alt="c3vision" />
          </Link>
          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-item nav-link" to="/modules">{t('layout.modules')}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-item nav-link" to="/about">{t('layout.about')}</Link>
              </li>
            </ul>
          </div>
          {/* Button placed outside of collapsible section to ensure visibility */}
          <Link to="/register" className="btn btn-primary ms-3" style={{ backgroundColor: '#1520bf', borderColor: '#1520bf' }}>
            {t('layout.buttons.requestDemo')}
          </Link>
        </div>
      </nav>

      {/* Main Content Area */}
      <main role="main" className="container">
        <FlashMessage />
        <div className="row">
          {children}
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Layout;
